class OsPaymentsBraintree {

	static create_token($booking_form_wrapper){
	  // Gather additional customer data we may have collected in our form.
	  var name = jQuery($booking_form_wrapper).find('#payment_name_on_card');
	  var zip = jQuery($booking_form_wrapper).find('#payment_zip');
	  var additionalData = {
	    name: name ? name.value : undefined,
	    address_zip: zip ? zip.value : undefined,
	  };

	  OsPaymentsBraintree.hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
	    if (tokenizeErr) {
	    	if(tokenizeErr.code == 'HOSTED_FIELDS_FIELDS_INVALID' && tokenizeErr.details.invalidFields){
	    		jQuery.each(tokenizeErr.details.invalidFields, function(key, field){
	    			jQuery(field).addClass('braintree-hosted-fields-invalid');
	    		});
	    	}
	      latepoint_show_message_inside_element(tokenizeErr.message);
	      jQuery($booking_form_wrapper).find('.latepoint-next-btn').removeClass('os-loading');
	    }else{
	      // If we received a token, show the token ID.
	      jQuery($booking_form_wrapper).find('.latepoint-form #payment_token').val(payload.nonce);
	      jQuery($booking_form_wrapper).find('.latepoint-form').submit();
	    }
	  });
	}




	static init_cc_form(){
		braintree.client.create({
		  authorization: latepoint_helper.braintree_tokenization_key
		}, function(err, clientInstance) {
		  if (err) {
		    console.error(err);
		    return;
		  }

		  braintree.hostedFields.create({
		    client: clientInstance,
		    styles: {
		      'input': {
		        'font-size': '14px',
		        'font-family': latepoint_helper.body_font_family,
		        'font-weight': '500',
		        'color': '#32373c',
		      },
		      ':focus': {
		        'color': '#32373c'
		      },
		      '::placeholder': {
		        'color': '#AFB8D6'
		      },
		      '.valid': {
		        'color': '#32373c'
		      },
		      '.invalid': {
		        'color': '#d61515'
		      }
		    },
		    fields: {
		      number: {
		        selector: '#payment_card_number',
		        placeholder: jQuery('#payment_card_number').data('placeholder')
		      },
		      cvv: {
		        selector: '#payment_card_cvc',
		        placeholder: jQuery('#payment_card_cvc').data('placeholder')
		      },
		      expirationDate: {
		        selector: '#payment_card_expiration',
		        placeholder: jQuery('#payment_card_expiration').data('placeholder')
		      },
		    }
		  }, function(err, hostedFieldsInstance) {
		  	OsPaymentsBraintree.hostedFieldsInstance = hostedFieldsInstance;
		    if (err) {
		      console.error(err);
		      return;
		    }
		  });
		});
	}
}