// @codekit-prepend "os-time.js"
// @codekit-prepend "os-shared.js"
// @codekit-prepend "os-notifications.js";
// @codekit-prepend "os-actions.js"
// @codekit-prepend "os-payments-braintree.js"


function latepoint_reset_password_from_booking_init(){
  jQuery('.os-step-existing-customer-login-w').hide();
  jQuery('.os-password-reset-form-holder').on('click', '.password-reset-back-to-login', function(){
    jQuery('.os-password-reset-form-holder').html('');
    jQuery('.os-step-existing-customer-login-w').show();
    return false;
  });
}

function latepoint_password_changed_show_login(response){
  jQuery('.os-step-existing-customer-login-w').show();
  jQuery('.os-password-reset-form-holder').html('');
  latepoint_show_message_inside_element(response.message, jQuery('.os-step-existing-customer-login-w'), 'success');
}

function latepoint_hide_message_inside_element($elem = jQuery('.latepoint-body')){
  if($elem.length && $elem.find('.latepoint-message').length){
    $elem.find('.latepoint-message').remove();
  }
}

function latepoint_show_message_inside_element(message, $elem = jQuery('.latepoint-body'), message_type = 'error'){
  if($elem.length){
    if($elem.find('.latepoint-message').length){
      $elem.find('.latepoint-message').removeClass('latepoint-message-success').removeClass('latepoint-message-error').addClass('latepoint-message-'+ message_type +'').html(message).show();
    }else{
      $elem.prepend('<div class="latepoint-message latepoint-message-'+ message_type +'">' + message + '</div>');
    }
  }
}

function latepoint_clear_step_vars(step_name, $form){
  switch(step_name) {
    case 'locations':
      $form.find('input[name="booking[start_date]"]').val('');
      break;
    case 'services':
      $form.find('input[name="booking[service_id]"]').val('');
      break;
    case 'agents':
      $form.find('input[name="booking[agent_id]"]').val('');
      break;
    case 'datepicker':
      $form.find('input[name="booking[start_date]"]').val('');
      $form.find('input[name="booking[start_time]"]').val('');
      break;
  }
}

( function( $ ) {
  "use strict";

  var latepoint_stripe;
  var latepoint_stripe_elements;
  var latepoint_stripe_elements_card_number;
  var latepoint_stripe_elements_card_expiry;
  var latepoint_stripe_elements_card_cvc;

  function latepoint_register_stripe_card_elements(elements) {

    // Listen for errors from each Element, and show error messages in the UI.
    elements.forEach(function(element) {
      element.on('change', function(event) {
        if (event.error) {
          latepoint_show_message_inside_element(event.error.message);
        } else {
          latepoint_hide_message_inside_element();
        }
      });
    });

  }

  function latepoint_stripe_create_token($booking_form_wrapper){
    // Gather additional customer data we may have collected in our form.
    var name = jQuery($booking_form_wrapper).find('#payment_name_on_card');
    var zip = jQuery($booking_form_wrapper).find('#payment_zip');
    var additionalData = {
      name: name ? name.value : undefined,
      address_zip: zip ? zip.value : undefined,
    };

    // Use Stripe.js to create a token. We only need to pass in one Element
    // from the Element group in order to create a token. We can also pass
    // in the additional customer data we collected in our form.
    latepoint_stripe.createToken(latepoint_stripe_elements_card_number, additionalData).then(function(result) {
      // Stop loading!
      // example.classList.remove('submitting');

      if (result.token) {
        // If we received a token, show the token ID.
        jQuery($booking_form_wrapper).find('.latepoint-form #payment_token').val(result.token.id);
        jQuery($booking_form_wrapper).find('.latepoint-form').submit();
      } else {
        latepoint_show_message_inside_element(result.error.message);
        jQuery($booking_form_wrapper).find('.latepoint-next-btn').removeClass('os-loading');
      }
    });
  }


  function latepoint_init_stripe_form(){
    latepoint_stripe = Stripe(latepoint_helper.stripe_key);

    latepoint_stripe_elements = latepoint_stripe.elements();


    var elementStyles = {
      base: {
        fontFamily: latepoint_helper.body_font_family,
        fontSize: '14px',
        fontWeight: 500,
        '::placeholder': {
          color: '#AFB8D6',
        },
      }
    };

    var elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    latepoint_stripe_elements_card_number = latepoint_stripe_elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
      placeholder: jQuery('#payment_card_number').data('placeholder')
    });
    latepoint_stripe_elements_card_number.mount('#payment_card_number');

    latepoint_stripe_elements_card_expiry = latepoint_stripe_elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
      placeholder: jQuery('#payment_card_expiration').data('placeholder')
    });
    latepoint_stripe_elements_card_expiry.mount('#payment_card_expiration');

    latepoint_stripe_elements_card_cvc = latepoint_stripe_elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
      placeholder: jQuery('#payment_card_cvc').data('placeholder')
    });
    latepoint_stripe_elements_card_cvc.mount('#payment_card_cvc');

    latepoint_register_stripe_card_elements([latepoint_stripe_elements_card_number, latepoint_stripe_elements_card_expiry, latepoint_stripe_elements_card_cvc]);
  }

  function latepoint_init_step(step_name){
    switch(step_name){
      case 'datepicker':
        latepoint_init_step_datepicker();
      break;
      case 'contact':
        latepoint_init_step_contact();
      break;
      case 'agents':
        latepoint_init_step_agents();
      break;
      case 'locations':
        latepoint_init_step_locations();
      break;
      case 'services':
        latepoint_init_step_services()
      break;
      case 'payment':
        latepoint_init_step_payment()
      break;
      case 'confirmation':
        latepoint_init_step_confirmation()
      break;
    }
  }


  function show_next_btn($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-next-btn').removeClass('disabled');
  }


  function hide_next_btn($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-next-btn').addClass('disabled');
  }


  function show_prev_btn($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-prev-btn').fadeIn(200);
    $booking_form_wrapper.find('.latepoint-footer').slideDown(200);
  }

  function hide_prev_btn($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-prev-btn').fadeOut(200);
  }


  function day_timeslots($day){
      var $booking_form_wrapper = $day.closest('.latepoint-booking-form-element');
      $day.addClass('selected');

      var service_duration = $day.data('service-duration');
      var interval = $day.data('interval');
      var bookings = $day.data('bookings');
      var work_start_minutes = $day.data('work-start-time');
      var work_end_minutes = $day.data('work-end-time');
      var booked_periods_arr = bookings.split(',');
      var $timeslots = $booking_form_wrapper.find('.timeslots');
      $timeslots.html('');
      
      var work_periods_arr = $day.data('work-periods').split(',');

      if(work_start_minutes < work_end_minutes){
        for(var current_minutes = parseInt(work_start_minutes); current_minutes <= parseInt(work_end_minutes); current_minutes+=interval){
          var ampm = latepoint_am_or_pm(current_minutes);

          var timeslot_class = 'dp-timeslot';
          if(latepoint_is_timeframe_in_periods(current_minutes, current_minutes + service_duration, booked_periods_arr)) timeslot_class+= ' is-booked';
          if(!latepoint_is_timeframe_in_periods(current_minutes, current_minutes + service_duration, work_periods_arr, true)) timeslot_class+= ' is-off';
          var tick_html = '';
          if((current_minutes % 60) == 0){
            timeslot_class+= ' with-tick';
            tick_html = '<span class="dp-tick"><strong>'+latepoint_minutes_to_hours(current_minutes)+'</strong>'+' '+ampm+'</span>';
          }
          var timeslot_label = latepoint_minutes_to_hours_and_minutes(current_minutes)+' '+ampm;
          if(latepoint_show_booking_end_time()){
            var end_minutes = current_minutes + service_duration;
            var end_minutes_ampm = latepoint_am_or_pm(end_minutes);
            timeslot_label+= ' - ' + latepoint_minutes_to_hours_and_minutes(end_minutes)+' '+end_minutes_ampm;
          }
          timeslot_label = timeslot_label.trim();
          $timeslots.append('<div class="'+timeslot_class+'" data-minutes="' + current_minutes + '"><span class="dp-label">'+ timeslot_label +'</span>'+tick_html+'</div>');
        }
      }else{
        // No working hours this day
        $timeslots.append('<div class="not-working-message">' + latepoint_helper.msg_not_available + "</div>");
      }
  }



  function os_init_timeslots(){
    $('.dp-timeslot').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      if($(this).hasClass('is-booked') || $(this).hasClass('is-off')){
        // Show error message that you cant select a booked period
      }else{
        if($(this).hasClass('selected')){
          $(this).removeClass('selected');
          $(this).find('.dp-success-label').remove();
          $booking_form_wrapper.find('.latepoint_start_time').val('');
          hide_next_btn($booking_form_wrapper);
        }else{
          $booking_form_wrapper.find('.dp-timeslot.selected').removeClass('selected').find('.dp-success-label').remove();
          var selected_timeslot_time = $(this).find('.dp-label').html();
          $(this).addClass('selected').find('.dp-label').html('<span class="dp-success-label">' + $booking_form_wrapper.find('.latepoint-form').data('selected-label') + '</span>' + selected_timeslot_time);
          $booking_form_wrapper.find('.latepoint_start_time').val($(this).data('minutes'));
          show_next_btn($booking_form_wrapper);
        }
      }
      return false;
    });
  }

  function os_init_monthly_calendar_navigation(){
    $('.os-month-next-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      var next_month_route_name = $(this).data('route');
      if($booking_form_wrapper.find('.os-monthly-calendar-days-w.active + .os-monthly-calendar-days-w').length){
        $booking_form_wrapper.find('.os-monthly-calendar-days-w.active').removeClass('active').next('.os-monthly-calendar-days-w').addClass('active');
        latepoint_calendar_set_month_label($booking_form_wrapper);
      }else{
        // TODO add condition to check maximum number months to call into the future
        if(true){
          var $btn = $(this);
          $btn.addClass('os-loading');
          var $calendar_element = $booking_form_wrapper.find('.os-monthly-calendar-days-w').last();
          var calendar_year = $calendar_element.data('calendar-year');
          var calendar_month = $calendar_element.data('calendar-month');
          if(calendar_month == 12){
            calendar_year = calendar_year + 1;
            calendar_month = 1;
          }else{
            calendar_month = calendar_month + 1;
          }
          var booking_info = { target_date_string: calendar_year + '-' + calendar_month + '-1', 
                                location_id: $booking_form_wrapper.find('.latepoint_location_id').val(), 
                                agent_id: $booking_form_wrapper.find('.latepoint_agent_id').val(), 
                                service_id: $booking_form_wrapper.find('.latepoint_service_id').val() };
          var data = { action: 'latepoint_route_call', route_name: next_month_route_name, params: booking_info, layout: 'none', return_format: 'json' }
          $.ajax({
            type : "post",
            dataType : "json",
            url : latepoint_helper.ajaxurl,
            data : data,
            success: function(data){
              $btn.removeClass('os-loading');
              if(data.status === "success"){
                $booking_form_wrapper.find('.os-months').append(data.message);
                $booking_form_wrapper.find('.os-monthly-calendar-days-w.active').removeClass('active').next('.os-monthly-calendar-days-w').addClass('active');
                latepoint_calendar_set_month_label($booking_form_wrapper);
              }else{
                // console.log(data.message);
              }
            }
          });
        }
      }
      latepoint_calendar_show_or_hide_prev_next_buttons($booking_form_wrapper);
      return false;
    });
    $('.os-month-prev-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      if($booking_form_wrapper.find('.os-monthly-calendar-days-w.active').prev('.os-monthly-calendar-days-w').length){
        $booking_form_wrapper.find('.os-monthly-calendar-days-w.active').removeClass('active').prev('.os-monthly-calendar-days-w').addClass('active');
        latepoint_calendar_set_month_label($booking_form_wrapper);
      }
      latepoint_calendar_show_or_hide_prev_next_buttons($booking_form_wrapper);
      return false;
    });
  }

  function latepoint_calendar_set_month_label($booking_form_wrapper){
    $booking_form_wrapper.find('.os-current-month-label').text($booking_form_wrapper.find('.os-monthly-calendar-days-w.active').data('calendar-month-label'));
  }


  function latepoint_calendar_show_or_hide_prev_next_buttons($booking_form_wrapper){
    $booking_form_wrapper.find('.os-current-month-label').html($booking_form_wrapper.find('.os-monthly-calendar-days-w.active .os-monthly-calendar-days').data('calendar-month-label'));
    if($booking_form_wrapper.find('.os-monthly-calendar-days-w.active').prev('.os-monthly-calendar-days-w').length){
      $booking_form_wrapper.find('.os-month-prev-btn').removeClass('disabled');
    }else{
      $booking_form_wrapper.find('.os-month-prev-btn').addClass('disabled');
    }
  }


  function latepoint_init_step_datepicker(){
    os_init_timeslots();
    os_init_monthly_calendar_navigation();
    $('.os-months').on('click', '.os-day', function(){
      if($(this).hasClass('os-day-passed')) return false;
      if($(this).hasClass('os-not-in-allowed-period')) return false;
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      $booking_form_wrapper.find('.os-day.selected').removeClass('selected');
      day_timeslots($(this));
      os_init_timeslots();
      $('.times-header span').text($(this).data('nice-date'));
      $booking_form_wrapper.find('.time-selector-w').slideDown(200, function(){
        var $scrollable_wrapper = $booking_form_wrapper.find('.latepoint-body');
        $scrollable_wrapper.stop().animate({
          scrollTop: $scrollable_wrapper[0].scrollHeight
        }, 200);
      });
      $booking_form_wrapper.find('.latepoint_start_date').val($(this).data('date'));
      $booking_form_wrapper.find('.latepoint_start_time').val('');
      hide_next_btn($booking_form_wrapper);
      return false;
    });
  }

  function latepoint_init_step_payment(){
    $('.latepoint-booking-form-element').on('click', '.os-payments li a', function(){
    });
    if(latepoint_helper.is_braintree_active){
      OsPaymentsBraintree.init_cc_form();
    }
    if(latepoint_helper.is_stripe_active){
      latepoint_init_stripe_form();
    }
  }


  function latepoint_init_step_services(){
    $('.os-service-category-info').on('click', function(){
      var $category_wrapper = $(this).closest('.os-service-category-w');
      var $main_parent = $(this).closest('.os-service-categories-main-parent');
      if($category_wrapper.hasClass('selected')){
        $category_wrapper.removeClass('selected');
        if($category_wrapper.parent().closest('.os-service-category-w').length){
          $category_wrapper.parent().closest('.os-service-category-w').addClass('selected');
        }else{
          $main_parent.removeClass('show-selected-only');
        }
      }else{
        $main_parent.find('.os-service-category-w.selected').removeClass('selected');
        $main_parent.addClass('show-selected-only');
        $category_wrapper.addClass('selected');
      }
      return false;
    });
    $('.os-services li a').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      $(this).closest('ul').find('li.selected').removeClass('selected');
      $(this).closest('li').addClass('selected');
      $booking_form_wrapper.find('.latepoint_service_id').val($(this).data('service-id'));
      show_next_btn($booking_form_wrapper);
      return false;
    });
  }

  function latepoint_init_step_locations(){
    $('.os-locations li a').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      $(this).closest('ul').find('li.selected').removeClass('selected');
      $(this).closest('li').addClass('selected');
      $booking_form_wrapper.find('.latepoint_location_id').val($(this).data('location-id'));
      show_next_btn($booking_form_wrapper);
      return false;
    });
  }

  function latepoint_init_step_agents(){
    $('.os-agents li a').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      $(this).closest('ul').find('li.selected').removeClass('selected');
      $(this).closest('li').addClass('selected');
      $booking_form_wrapper.find('.latepoint_agent_id').val($(this).data('agent-id'));
      show_next_btn($booking_form_wrapper);
      return false;
    });
    $('.os-agents li .os-agent-details-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      var agent_id = $(this).data('agent-id');
      $booking_form_wrapper.find('.os-agent-bio-popup.active').removeClass('active');
      $booking_form_wrapper.find('#osAgentBioPopup' + agent_id).addClass('active');
      return false;
    });
    $('.os-agent-bio-close').on('click', function(){
      $(this).closest('.os-agent-bio-popup').removeClass('active');
      return false;
    });
  }


  function latepoint_init_step_confirmation(){

    $('.latepoint-booking-form-element').on('click', '.set-customer-password-btn', function(){
      var $btn = $(this);
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');

      $btn.addClass('os-loading');
      var params = { account_nonse: $('input[name="account_nonse"]').val(), password: $('input[name="customer[password]"]').val(), password_confirmation: $('input[name="customer[password_confirmation]"]').val()}
      var data = { action: 'latepoint_route_call', route_name: $(this).data('btn-action'), params: $.param(params), layout: 'none', return_format: 'json' }
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          $btn.removeClass('os-loading');
          if(data.status === "success"){
            $booking_form_wrapper.find('.step-confirmation-set-password').html(latepoint_generate_form_message_html(data.message, 'success'));
          }else{
            latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.step-confirmation-set-password'), 'error');
          }
        }
      });
    });

    $('.latepoint-booking-form-element').on('click', '.show-set-password-fields', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');

      $booking_form_wrapper.find('.step-confirmation-set-password').show();
      $(this).closest('.info-box').hide();
      return false;
    });
  }


  function latepoint_init_step_contact(){
    latepoint_init_facebook_login();
    latepoint_init_google_login();
    latepoint_init_form_masks();

    $('.step-contact-w').on('keyup', '.os-form-control.required', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      if(latepoint_validate_fields($booking_form_wrapper.find('.step-contact-w .os-form-control.required'))){
      }else{
      }
    });

    // Init Logout button
    $('.step-customer-logout-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      var data = { action: 'latepoint_route_call', route_name: $(this).data('btn-action'), layout: 'none', return_format: 'json' }
      latepoint_step_content_change_start($booking_form_wrapper);
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          if(data.status === "success"){
            $booking_form_wrapper.find('.latepoint_customer_id').val('');
            latepoint_step_content_change_end(data.message, $booking_form_wrapper);
            latepoint_init_step('contact');
          }else{
            latepoint_step_content_change_end(false, $booking_form_wrapper);
          }
        }
      });
      return false;
    });

    // Init Login Existing Customer Button
    $('.step-login-existing-customer-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      var params = { email: $booking_form_wrapper.find('.os-step-existing-customer-login-w input[name="customer_login[email]"]').val(), password: $booking_form_wrapper.find('.os-step-existing-customer-login-w input[name="customer_login[password]"]').val()}
      var data = { action: 'latepoint_route_call', route_name: $(this).data('btn-action'), params: $.param(params), layout: 'none', return_format: 'json' }
      latepoint_step_content_change_start($booking_form_wrapper);
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
            if(data.status === "success"){
              latepoint_step_content_change_end(data.message, $booking_form_wrapper);
              $booking_form_wrapper.find('.latepoint_customer_id').val(data.customer_id);
              latepoint_init_step('contact');
            }else{
              latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.os-step-existing-customer-login-w'));
              latepoint_step_content_change_end(false, $booking_form_wrapper);
            }
          }
        });
      return false;
    });
  }

  function latepoint_step_content_change_start($booking_form_wrapper){
    $booking_form_wrapper.removeClass('step-content-loaded').addClass('step-content-loading');
  }

  // TODO
  function latepoint_step_content_change_end(new_content, $booking_form_wrapper){
    if(new_content) $booking_form_wrapper.find('.latepoint-body .latepoint-step-content').replaceWith(new_content);
    $booking_form_wrapper.removeClass('step-content-loading').addClass('step-content-mid-loading');
    setTimeout(function(){
      $booking_form_wrapper.removeClass('step-content-mid-loading').addClass('step-content-loaded');
    }, 50);
  }

  function latepoint_init_facebook_login(){
    $('#facebook-signin-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      FB.login(function(response){
        if (response.status === 'connected' && response.authResponse) {
          var params = { token: response.authResponse.accessToken};
          var data = { action: 'latepoint_route_call', route_name: $booking_form_wrapper.find('#facebook-signin-btn').data('login-action'), params: $.param(params), layout: 'none', return_format: 'json' };
          latepoint_step_content_change_start($booking_form_wrapper);
          $.ajax({
            type : "post",
            dataType : "json",
            url : latepoint_helper.ajaxurl,
            data : data,
            success: function(data){
              if(data.status === "success"){
                latepoint_step_content_change_end(data.message, $booking_form_wrapper);
                $booking_form_wrapper.find('.latepoint_customer_id').val(data.customer_id);
                latepoint_init_step('contact');
              }else{
                latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.os-step-existing-customer-login-w '));
                latepoint_step_content_change_end(false, $booking_form_wrapper);
              }
            }
          });
        } else {
          
        }
      }, {scope: 'public_profile,email'});
    });
  }

  function latepoint_init_google_login(){
    if(!$('#google-signin-btn').length || !$('.latepoint-booking-form-element').length) return;
    var googleUser = {};

    var $booking_form_wrappers = $('.latepoint-booking-form-element');
    $booking_form_wrappers.each(function(){
      var $booking_form_wrapper = $(this);
      gapi.load('auth2', function(){
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        var auth2 = gapi.auth2.init({
          client_id: $booking_form_wrapper.find('meta[name=google-signin-client_id]').attr("content"),
          cookiepolicy: 'single_host_origin',
          // Request scopes in addition to 'profile' and 'email'
          //scope: 'additional_scope'
        });

        auth2.attachClickHandler($booking_form_wrapper.find('#google-signin-btn')[0], {},
          function(googleUser) {
            var params = { token: googleUser.getAuthResponse().id_token};
            var data = { action: 'latepoint_route_call', route_name: $booking_form_wrapper.find('#google-signin-btn').data('login-action'), params: $.param(params), layout: 'none', return_format: 'json' };
            latepoint_step_content_change_start($booking_form_wrapper);
            $.ajax({
              type : "post",
              dataType : "json",
              url : latepoint_helper.ajaxurl,
              data : data,
              success: function(data){
                if(data.status === "success"){
                  latepoint_step_content_change_end(data.message, $booking_form_wrapper);
                  $booking_form_wrapper.find('.latepoint_customer_id').val(data.customer_id);
                  latepoint_init_step('contact');
                }else{
                  latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.os-step-existing-customer-login-w '));
                  latepoint_step_content_change_end(false, $booking_form_wrapper);
                }
              }
            });
          }, function(error) {
            // console.log(JSON.stringify(error, undefined, 2));
          });
      });
    });
  }


  function latepoint_progress_prev($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-progress li.active').removeClass('active').prev('li').addClass('active');
  }


  function latepoint_progress_next($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-progress li.active').removeClass('active').addClass('complete').next('li').addClass('active');
  }


  function latepoint_next_step_description($booking_form_wrapper){
    $booking_form_wrapper.removeClass('step-changed').addClass('step-changing');
    setTimeout(function(){
      $booking_form_wrapper.find('.latepoint-step-desc').html($booking_form_wrapper.find('.latepoint-step-desc-library.active').removeClass('active').next('.latepoint-step-desc-library').addClass('active').html());
      $booking_form_wrapper.find('.os-heading-text').html($booking_form_wrapper.find('.os-heading-text-library.active').removeClass('active').next('.os-heading-text-library').addClass('active').html());
      setTimeout(function(){
        $booking_form_wrapper.removeClass('step-changing').addClass('step-changed');
      }, 50);
    }, 500);
  }
  function latepoint_prev_step_description($booking_form_wrapper){
    $booking_form_wrapper.removeClass('step-changed').addClass('step-changing');
    setTimeout(function(){
      $booking_form_wrapper.find('.latepoint-step-desc').html($booking_form_wrapper.find('.latepoint-step-desc-library.active').removeClass('active').prev('.latepoint-step-desc-library').addClass('active').html());
      $booking_form_wrapper.find('.os-heading-text').html($booking_form_wrapper.find('.os-heading-text-library.active').removeClass('active').prev('.os-heading-text-library').addClass('active').html());
      setTimeout(function(){
        $booking_form_wrapper.removeClass('step-changing').addClass('step-changed');
      }, 50);
    }, 500);
  }


  function latepoint_validate_fields($fields){
    var is_valid = true;
    $fields.each(function(index){
      if($(this).val() == ''){
        is_valid = false;
        return false;
      }
    });
    return is_valid;
  }


  function latepoint_init_booking_form(){
    $('.latepoint-lightbox-close').on('click', function(){
      $('body').removeClass('latepoint-lightbox-active');
      $('.latepoint-lightbox-w').remove();
      return false;
    });


    $('.latepoint-booking-form-element .latepoint-form').on('submit', function(e){
      var $booking_form = $(this);
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      e.preventDefault();
      var form_data = $booking_form.serialize();
      var data = { action: 'latepoint_route_call', route_name: $booking_form.data('route-name'), params: form_data, layout: 'none', return_format: 'json'}
      $booking_form_wrapper.removeClass('step-content-loaded').addClass('step-content-loading');
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          if(data.status === "success"){
            $booking_form_wrapper.find('.latepoint_current_step').val(data.step_name);
            setTimeout(function(){
              $booking_form_wrapper.removeClass('step-content-loading').addClass('step-content-mid-loading');
              $booking_form_wrapper.find('.latepoint-body').html(data.message);
              latepoint_init_step(data.step_name);
              setTimeout(function(){
                $booking_form_wrapper.removeClass('step-content-mid-loading').addClass('step-content-loaded');
                $booking_form_wrapper.find('.latepoint-next-btn, .latepoint-prev-btn').removeClass('os-loading');
              }, 50);
            }, 500);

            if(data.is_pre_last_step){
              $booking_form_wrapper.find('.latepoint-next-btn span').text($booking_form_wrapper.find('.latepoint-next-btn').data('pre-last-step-label'));
            }else{
              $booking_form_wrapper.find('.latepoint-next-btn span').text($booking_form_wrapper.find('.latepoint-next-btn').data('label'));
            }
            if(data.is_last_step){
              $booking_form_wrapper.find('.latepoint-footer').hide();
            }else{
              if(data.show_next_btn === true){
                show_next_btn($booking_form_wrapper);
              }else{
                hide_next_btn($booking_form_wrapper);
              }
              if(data.show_prev_btn === true){
                show_prev_btn($booking_form_wrapper);
              }else{
                hide_prev_btn($booking_form_wrapper);
              }
            }
            

            if($booking_form.data('step-direction') == 'prev'){
              latepoint_progress_prev($booking_form_wrapper);
              latepoint_prev_step_description($booking_form_wrapper);
            }else{
              latepoint_progress_next($booking_form_wrapper);
              latepoint_next_step_description($booking_form_wrapper);
            }
          }else{
            $booking_form_wrapper.removeClass('step-content-loading').addClass('step-content-loaded');
            $booking_form_wrapper.find('.latepoint-next-btn, .latepoint-prev-btn').removeClass('os-loading');
            latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.latepoint-body'));
          }
        }
      });
    });

    

    $('.latepoint-booking-form-element .latepoint-next-btn').on('click', function(e){
      if($(this).hasClass('disabled') || $(this).hasClass('os-loading')) return false;
      var $booking_form = $(this).closest('.latepoint-form');
      var $booking_form_wrapper = $booking_form.closest('.latepoint-booking-form-element');

      $booking_form.data('step-direction', 'next');
      $booking_form.data('route-name', $booking_form.data('next-step-route-name'));
      if($booking_form_wrapper.find('.latepoint_current_step').val() == 'payment'){
        if(latepoint_helper.is_stripe_active){
          latepoint_stripe_create_token($booking_form_wrapper);
        }
        if(latepoint_helper.is_braintree_active){
          OsPaymentsBraintree.create_token($booking_form_wrapper);
        }
      }else{
        $booking_form.submit();
      }
      $(this).addClass('os-loading');
      return false;
    });

    $('.latepoint-booking-form-element .latepoint-prev-btn').on('click', function(e){
      if($(this).hasClass('disabled') || $(this).hasClass('os-loading')) return false;
      var $booking_form = $(this).closest('.latepoint-form');
      var $booking_form_wrapper = $booking_form.closest('.latepoint-booking-form-element');

      latepoint_clear_step_vars($booking_form.find('.latepoint_current_step').val(), $booking_form);
      $booking_form.data('step-direction', 'prev');
      $booking_form.data('route-name', $booking_form.data('prev-step-route-name')).submit();
      $(this).addClass('os-loading');
      return false;
    });
  }

  // DOCUMENT READY
  $( function() {


    if($('.latepoint-booking-form-element').length){
      latepoint_init_booking_form();
      $('.latepoint-booking-form-element').each(function(){
        latepoint_init_step($(this).find('.latepoint_current_step').val());
      });
    }

    $('.latepoint-request-booking-cancellation').on('click', function(){
      var $this = $(this);
      var $booking_box = $this.closest('.customer-booking');

      var route = $(this).data('route');
      var params = {id: $booking_box.data('id')};

      var data = { action: 'latepoint_route_call', route_name: route, params: params, layout: 'none', return_format: 'json' }
      $this.addClass('os-loading');
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          if(data.status === "success"){
            location.reload();
          }else{
            $this.removeClass('os-loading');
          }
        }
      });
    });


    $('body').on('click', '.os-step-tabs .os-step-tab', function(){
      $(this).closest('.os-step-tabs').find('.os-step-tab').removeClass('active');
      $(this).addClass('active');
      var target = $(this).data('target');
      $(this).closest('.os-step-tabs-w').find('.os-step-tab-content').hide();
      $(target).show();
    });

    $('body').on('keyup', '.os-form-group .os-form-control', function(){
      if($(this).val()){
        $(this).closest('.os-form-group').addClass('has-value');
      }else{
        $(this).closest('.os-form-group').removeClass('has-value');
      }
    });

    $('.latepoint-tab-triggers').on('click', '.latepoint-tab-trigger', function(){
      var $tabs_wrapper = $(this).closest('.latepoint-tabs-w')
      $tabs_wrapper.find('.latepoint-tab-trigger.active').removeClass('active');
      $tabs_wrapper.find('.latepoint-tab-content').removeClass('active');
      $(this).addClass('active');
      $tabs_wrapper.find('.latepoint-tab-content' + $(this).data('tab-target')).addClass('active');
      return false;
    });


    // Main Button to trigger lightbox opening
    $('.latepoint-book-button, .os_trigger_booking').on('click', function(){
      var $this = $(this);
      var route = latepoint_helper.booking_button_route;
      var params = {};
      var restrictions = {};
      if($this.data('show-service-categories')) restrictions.show_service_categories = $this.data('show-service-categories');
      if($this.data('show-locations')) restrictions.show_locations = $this.data('show-locations');
      if($this.data('show-services')) restrictions.show_services = $this.data('show-services');
      if($this.data('show-agents')) restrictions.show_agents = $this.data('show-agents');
      if($this.data('selected-location')) restrictions.selected_location = $this.data('selected-location');
      if($this.data('selected-agent')) restrictions.selected_agent = $this.data('selected-agent');
      if($this.data('selected-service')) restrictions.selected_service = $this.data('selected-service');
      if($this.data('selected-service-category')) restrictions.selected_service_category = $this.data('selected-service-category');
      if($this.data('calendar-start-date')) restrictions.calendar_start_date = $this.data('calendar-start-date');

      if($.isEmptyObject(restrictions) == false) params.restrictions = restrictions;

      var data = { action: 'latepoint_route_call', route_name: route, params: params, layout: 'none', return_format: 'json' }
      $this.addClass('os-loading');
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          if(data.status === "success"){
            latepoint_show_data_in_lightbox(data.message, 'latepoint-lightbox-v2');
            latepoint_init_booking_form();
            latepoint_init_step(data.step);
            $('body').addClass('latepoint-lightbox-active');
            $this.removeClass('os-loading');
            
          }else{
            $this.removeClass('os-loading');
            // console.log(data.message);
          }
        }
      });

      return false;
    });



  });


} )( jQuery );
